<!-- 列表每页条数：每页：10条 V -->
<template>
  <div class="pageSize">
    <span>每页：</span>
    <el-popover
      placement="bottom"
      trigger="click"
      popper-class="popPageSize"
      v-model="isPageSize"
    >
      <span slot="reference"
        >{{ pageSize }}条<em class="el-icon-caret-bottom"></em
      ></span>
      <ul class="scroll">
        <li
          v-for="(item, index) in pageList"
          :key="'pagesize_' + index"
          @click="
            isPageSize = false;
            $emit('back', item);
          "
        >
          {{ item }}条
        </li>
      </ul>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "g-listpagesize",
  props: {
    pageList: Array,
    pageSize: Number,
  },
  data() {
    return {
      isPageSize: false, //选择每页条数弹窗开关
    };
  },
};
</script>

<style lang="less" scoped>
.pageSize {
  .el-popover__reference {
    .el-icon-caret-bottom {
      font-size: 14px;
      color: #bbb;
      margin-left: 3px;
    }
  }
}
</style>
<style lang="less">
// 每页条数选项弹窗
.popPageSize {
  min-width: 90px;
  border-radius: 2px;
  padding: 5px 0px;
  ul {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    li {
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: #f5f7fa;
      }
    }
  }
}
</style>
