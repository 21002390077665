// 直播接口
export default function (axios) {
  return {
    // *****************************************************
    // 主列表页
    // *****************************************************
    // /anon/tencentLiveCtl/getUserLiveList
    zb_listAllApi(params) {
      return axios.post("/anon/tencentLiveCtl/getUserLiveList", params);
    },
    // 列表-全部
    // zb_listAllApi(params) {
    //   return axios.post("/anon/tencentLiveCtl/getLiveList", params);
    // },
    //列表-我学习的
    zb_listStudyApi(params) {
      return axios.post("/anon/tencentLiveCtl/getSeenLiveList", params);
    },
    //列表-我购买的
    zb_listBuyApi(params) {
      return axios.post("/anon/tencentLiveCtl/getBuyLiveList", params);
    },
    //列表-我发布的
    zb_listCreatApi(params) {
      return axios.post("/anon/tencentLiveCtl/getMyLiveList", params);
    },
    // 新增
    zb_saveApi(params, type) {
      let api =
        type == "edit"
          ? "/anon/tencentLiveCtl/updateLiveRoom/" + params.liveId
          : "/anon/tencentLiveCtl/saveLiveRoom";
      return axios.post(api, params);
    },
    // 删除
    zb_delApi(params) {
      return axios.delete("/anon/tencentLiveCtl/deleteLiveRoom/" + params.id);
    },
    // 查详情
    zb_detailApi(params, nose) {
      return axios.get(
        `/anon/tencentLiveCtl/getById${nose ? "Nose" : ""}/` + params.id
      );
    },

    // *****************************************************
    // ****
    // *****************************************************

    //获取播放地址
    getPlayUrl(params) {
      return axios.post("/anon/tencentLiveCtl/getPlayUrl", {}, { params });
    },
    // 获取推流地址
    getPushUrl(params) {
      return axios.post("/anon/tencentLiveCtl/getPushUrl", {}, { params });
    },
    //获取直播标签
    listLiveTag() {
      return axios.get("/anon/liveTagCtl/getLiveTag");
    },
    //发布直播
    addLive(data, params) {
      return axios.post("/anon/tencentLiveCtl/saveLiveRoom", data, { params });
    },
    // /anon/tencentLiveCtl/getRoomUsers   获取直播间用户信息
    getRoomUsers(params) {
      return axios.post("/anon/tencentLiveCtl/getRoomUsers", null, { params });
    },

    // /anon/tencentLiveCtl/saveRoomSetting  保存新增直播间设置
    zb_saveRoomSetting(data) {
      return axios.post("/anon/tencentLiveCtl/saveRoomSetting", data);
    },

    // /anon/tencentLiveCtl/uploadPlaybackFile
    zb_uploadPlaybackFile(data) {
      return axios.post("/anon/tencentLiveCtl/uploadPlaybackFile", data);
    },

    // /anon/tencentLiveCtl/putPlaybackUrl2LiveRoom
    // zb_putPlaybackUrl2LiveRoom(data) {
    //   return axios.post("/anon/tencentLiveCtl/putPlaybackUrl2LiveRoom", data);
    // },
  };
}
