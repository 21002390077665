// import {getProjectEdit} from "@/api/study/exam";

const state = {
  projectInfo: {},
}

const mutations = {
  SET_PROINFO: (state, data) => {
    state.projectInfo = data
  },
}

const actions = {
  // 更新项目信息
  getProInfo({ commit }, data) {
    console.log(commit)
    return new Promise((resolve, reject) => {
      this.$api.getProjectEdit(data).then(res => {
        if (res.code == '200') {
          resolve(res.data.result)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 缓存项目信息
  async setProInfo({ commit }, data) {
    commit('SET_PROINFO', data)
  },
}

export default {
  //namespaced: true,
  state,
  mutations,
  actions
}