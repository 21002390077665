export default {
  parent: "control",
  routers: [
    // 控制台
    {
      path: "main",
      name: "controlMain",
      component: () => import("@/views/manage/mainView.vue"),
      // redirect: { name: "MyDiary" },
      meta: { title: "控制台" },
    },
  ],
};
