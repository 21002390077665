import qs from "qs"; // 序列化

export function formData(url, data) {
  return {
    url: url,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data);// 将请求数据转换成功 formdata 接收格式
      },
    ],
  };
};

export default function (axios) {
  return {

    //项目标签
    addProTagReq(params) { return axios.post("/paramImportCtl/insertProjectLabel", {}, { params }) },//新增
    getProTagReq(params) { return axios.get("/paramImportCtl/selectProjectLabelListPage", params) },//查询
    editProTagReq(params) { return axios.post("/paramImportCtl/updateProjectLabel", {}, { params }) },//修改
    delProTagReq(params) { return axios.post("/paramImportCtl/updateProjectLabelStatus", {}, { params }) },//删除

    //问卷标签
    addPaperTagReq(params) { return axios.post("/paramImportCtl/insertPaperLabel", {}, { params }) },//新增
    getPaperTagReq(params) { return axios.get("/paramImportCtl/selectPaperLabelListPage", params) },//查询
    editPaperTagReq(params) { return axios.post("/paramImportCtl/updatePaperLabel", {}, { params }) },//修改
    delPaperTagReq(params) { return axios.post("/paramImportCtl/updatePaperLabelStatus", {}, { params }) },//删除

    //兴趣爱好
    addInterestReq(params) { return axios.post("/personParamCtl/insertInterest", {}, { params }) },//新增
    getInterestReq(params) { return axios.get("/personParamCtl/selectInterest", params) },//查询
    editInterestReq(params) { return axios.post("/personParamCtl/updateInterest", {}, { params }) },//修改
    delInterestReq(params) { return axios.post("/personParamCtl/updateInterestStatus", {}, { params }) },//删除

    // 职位诱惑
    addJobSpecialReq(params) { return axios.post("/personParamCtl/insertJobSpecial", {}, { params }) },//新增
    getJobSpecialReq(params) { return axios.get("/personParamCtl/selectJobSpecial", params) },//查询
    editJobSpecialReq(params) { return axios.post("/personParamCtl/updateJobSpecial", {}, { params }) },//修改
    delJobSpecialReq(params) { return axios.post("/personParamCtl/updateJobSpecialStatus", {}, { params }) },//删除

    // 政治面貌
    addPoliticalReq(params) { return axios.post("/personParamCtl/insertPolitical", {}, { params }) },//新增
    getPoliticalReq(params) { return axios.get("/personParamCtl/selectPolitical", params) },//查询
    editPoliticalReq(params) { return axios.post("/personParamCtl/updatePolitical", {}, { params }) },//修改
    delPoliticalReq(params) { return axios.post("/personParamCtl/updatePoliticalStatus", {}, { params }) },//删除

    // 年龄
    addAgeReq(params) { return axios.post("/personParamCtl/insertAge", {}, { params }) },//新增
    getAgeReq(params) { return axios.get("/personParamCtl/selectAge", params) },//查询
    editAgeReq(params) { return axios.post("/personParamCtl/updateAge", {}, { params }) },//修改
    delAgeReq(params) { return axios.post("/personParamCtl/updateAgeStatus", {}, { params }) },//删除

    // 薪资
    addSalaryReq(params) { return axios.post("/personParamCtl/insertSalary", {}, { params }) },//新增
    getSalaryReq(params) { return axios.get("/personParamCtl/selectSalary", params) },//查询
    editSalaryReq(params) { return axios.post("/personParamCtl/updateSalary", {}, { params }) },//修改
    delSalaryReq(params) { return axios.post("/personParamCtl/updateSalaryStatus", {}, { params }) },//删除

    // 经验
    addExperienceReq(params) { return axios.post("/personParamCtl/insertExperience", {}, { params }) },//新增
    getExperienceReq(params) { return axios.get("/personParamCtl/selectExperience", params) },//查询
    editExperienceReq(params) { return axios.post("/personParamCtl/updateExperience", {}, { params }) },//修改
    delExperienceReq(params) { return axios.post("/personParamCtl/updateExperienceStatus", {}, { params }) },//删除

    // 学校
    addSchoolReq(params) { return axios.post("/schoolParamCtl/insertSchool", params, { conType: 'application/json' }) },//新增
    getSchoolReq(params) { return axios.get("/schoolParamCtl/selectSchool", params) },//查询
    editSchoolReq(params) { return axios.post("/schoolParamCtl/updateSchool", params, { conType: 'application/json' }) },//修改
    delSchoolReq(params) { return axios.post("/schoolParamCtl/updateSchoolStatus", params) },//删除

    // 培养方式
    addCultMethodReq(params) { return axios.post("/schoolParamCtl/insertCultMethod", {}, { params }) },//新增
    getCultMethodReq(params) { return axios.get("/schoolParamCtl/selectCultMethod", params) },//查询
    editCultMethodReq(params) { return axios.post("/schoolParamCtl/updateCultMethod", {}, { params }) },//修改
    delCultMethodReq(params) { return axios.post("/schoolParamCtl/updateCultMethodStatus", {}, { params }) },//删除

    // 学位
    addDegreeReq(params) { return axios.post("/schoolParamCtl/insertDegree", {}, { params }) },//新增
    getDegreeReq(params) { return axios.get("/schoolParamCtl/selectDegree", params) },//查询
    editDegreeReq(params) { return axios.post("/schoolParamCtl/updateDegree", {}, { params }) },//修改
    delDegreeReq(params) { return axios.post("/schoolParamCtl/updateDegreeStatus", {}, { params }) },//删除

    // 学历
    addEduLevelReq(params) { return axios.post("/schoolParamCtl/insertEduLevel", {}, { params }) },//新增
    getEduLevelReq(params) { return axios.get("/schoolParamCtl/selectEduLevel", params) },//查询
    editEduLevelReq(params) { return axios.post("/schoolParamCtl/updateEduLevel", {}, { params }) },//修改
    delEduLevelReq(params) { return axios.post("/schoolParamCtl/updateEduLevelStatus", {}, { params }) },//删除

    // 属性
    addSchoolGradeReq(params) { return axios.post("/schoolParamCtl/insertSchoolGrade", {}, { params }) },//新增
    getSchoolGradeReq(params) { return axios.get("/schoolParamCtl/selectSchoolGrade", params) },//查询
    editSchoolGradeReq(params) { return axios.post("/schoolParamCtl/updateSchoolGrade", {}, { params }) },//修改
    delSchoolGradeReq(params) { return axios.post("/schoolParamCtl/updateSchoolGradeStatus", {}, { params }) },//删除

    // 性质
    addSchoolLevelReq(params) { return axios.post("/schoolParamCtl/insertSchoolLevel", {}, { params }) },//新增
    getSchoolLevelReq(params) { return axios.get("/schoolParamCtl/selectSchoolLevel", { params }) },//查询
    editSchoolLevelReq(params) { return axios.post("/schoolParamCtl/updateSchoolLevel", {}, { params }) },//修改
    delSchoolLevelReq(params) { return axios.post("/schoolParamCtl/updateSchoolLevelStatus", {}, { params }) },//删除

    // 类型
    addSchoolTypeReq(params) { return axios.post("/schoolParamCtl/insertSchoolType", {}, { params }) },//新增
    getSchoolTypeReq(params) { return axios.get("/schoolParamCtl/selectSchoolType", params) },//查询
    editSchoolTypeReq(params) { return axios.post("/schoolParamCtl/updateSchoolType", {}, { params }) },//修改
    delSchoolTypeReq(params) { return axios.post("/schoolParamCtl/updateSchoolTypeStatus", {}, { params }) },//删除

    // 企业性质
    addCompTypeReq(params) { return axios.post("/companyParamCtl/insertCompType", {}, { params }) },//新增
    getCompTypeReq(params) { return axios.get("/companyParamCtl/selectCompType", params) },//查询
    editCompTypeReq(params) { return axios.post("/companyParamCtl/updateCompType", {}, { params }) },//修改
    delCompTypeReq(params) { return axios.post("/companyParamCtl/updateCompTypeStatus", {}, { params }) },//删除

    // 企业规模
    addCompSizeReq(params) { return axios.post("/companyParamCtl/insertCompSize", {}, { params }) },//新增
    getCompSizeReq(params) { return axios.get("/companyParamCtl/selectCompSize", params) },//查询
    editCompSizeReq(params) { return axios.post("/companyParamCtl/updateCompSize", {}, { params }) },//修改
    delCompSizeReq(params) { return axios.post("/companyParamCtl/updateCompSizeStatus", {}, { params }) },//删除

    // 发票类型
    addReceiptlTypeReq(params) { return axios.post("/companyParamCtl/insertReceiptlType", {}, { params }) },//新增
    getReceiptlTypeReq(params) { return axios.get("/companyParamCtl/selectReceiptlType", params) },//查询
    editReceiptlTypeReq(params) { return axios.post("/companyParamCtl/updateReceiptlType", {}, { params }) },//修改
    delReceiptlTypeReq(params) { return axios.post("/companyParamCtl/updateReceiptlTypeStatus", {}, { params }) },//删除

    // 城市
    getCityReq(params) { return axios.get("/companyParamCtl/selectCity", params) },//查询
    getCityNameReq(params) { return axios.get("/companyParamCtl/selectCityNameById", params) },//根据id城市查询


    // 地铁线
    addSubwayLineReq(params) { return axios.post("/companyParamCtl/insertSubwayLine", {}, { params }) },//新增
    getSubwayLineReq(params) { return axios.get("/companyParamCtl/selectSubwayLineList", params) },//查询
    editSubwayLineReq(params) { return axios.post("/companyParamCtl/updateSubwayLine", {}, { params }) },//修改
    delSubwayLineReq(params) { return axios.post("/companyParamCtl/updateSubwayLineStatus", {}, { params }) },//删除
    getSubwayLineReq2(params) { return axios.get("/companyParamCtl/selectSubwayLineListById", { params }) },//查询-根据城市id查询地铁线

    //地铁站
    insertSubwayStationReq(params) { return axios.post("/companyParamCtl/insertSubwayStation", {}, { params }) },//新增
    updateSubwayStationStatusReq(params) { return axios.post("/companyParamCtl/updateSubwayStationStatus", {}, { params }) },//删除
    updateSubwayStationReq(params) { return axios.post("/companyParamCtl/updateSubwayStation", {}, { params }) },//修改


    // 商圈
    addAreaReq(params) { return axios.post("/companyParamCtl/insertArea", {}, { params }) },//新增
    getAreaReq(params) { return axios.get("/companyParamCtl/selectArea", params) },//查询
    editAreaReq(params) { return axios.post("/companyParamCtl/updateArea", {}, { params }) },//修改
    delAreaReq(params) { return axios.post("/companyParamCtl/updateAreaStatus", {}, { params }) },//删除

    //TreeTable组件/新增
    addTreeParamsReq(typeUrl, params) { return axios.post(typeUrl, {}, { params }) },
    //TreeTable组件/查询
    getTreeParamsReq(typeUrl) { return axios.get(typeUrl) },
    //TreeTable组件/修改
    editTreeParamsReq(typeUrl, params) { return axios.post(typeUrl, {}, { params }) },
    //TreeTable组件/删除
    delTreeParamsReq(typeUrl, params) { return axios.post(typeUrl, {}, { params }) },
  }
}