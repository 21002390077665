import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = {
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
  plugins: [],
};

let persisted = {
  local: {
    storage: localStorage,
    paths: [],
  },
  session: {
    storage: sessionStorage,
    paths: [],
  },
};
// 导入模块并做持久化处理
const req = require.context("./modules", false, /\.js$/);
req.keys().forEach((path) => {
  let name = path.replace("./", "").replace(".js", "");
  let model = req(path).default;
  store.modules[name] = model;
  if (model.local) {
    persisted.local.paths.push(name);
  } else if (model.session) {
    persisted.session.paths.push(name);
  }
});
for (let item of Object.values(persisted)) {
  if (item.paths.length) {
    store.plugins.push(createPersistedState(item));
  }
}

// 自动添加getters、mutations、actions
for (let module of [store, ...Object.values(store.modules)]) {
  for (let key of Object.keys(module.state)) {
    if (!module.getters?.[key]) {
      (module.getters || (module.getters = {}))[key] = (s) => {
        return s[key];
      };
    }
    let [first, ...rest] = key;
    let fName = "set" + first?.toUpperCase() + rest.join("");
    if (!module.mutations?.[fName]) {
      (module.mutations || (module.mutations = {}))[fName] = (s, v) => {
        Vue.set(s, key, v);
      };
    }
  }
  for (let name of Object.keys(module.mutations)) {
    if (!module.actions?.[name]) {
      (module.actions || (module.actions = {}))[name] = (c, v) => {
        c["commit"](name, v);
      };
    }
  }
}

Vue.use(Vuex);
export default new Vuex.Store(store);
