// 个人中心
export default function (axios) {
  return {
    // 个人数据统计

    p_learningStatisticsApi(params) {
      return axios.post("/statistics/learningStatistics", params);
    },
    // 班级（我的学习、我的订单、我的收藏共用）
    p_classListApi(params) {
      return axios.post("/cultivate/trainingClass/selectClassByTrainee", params);
    },
    // 我的收藏-课程列表
    p_courseListApi(params) {
      return axios.post("/collect/selectCollectList", params);
    },


    // 取消订单
    p_cancelOrderApi(params) {
      return axios.post("paperOrderCtl/cancel/orderId", {}, { params });
    },


  }
}