import Vue from "vue";

let req;
// 引入自定义方法
const utils = {};
req = require.context("./utils", false, /\.js$/);
req.keys().forEach((path) => {
  Object.assign(utils, req(path)?.default || {});
});
Vue.prototype.$utils = utils;
// 引入自定义插件
req = require.context("./plugins", false, /\.js$/);
req.keys().forEach((path) => {
  if (req(path)?.default) {
    Vue.use(req(path).default);
  }
});
// 引入全局样式
req = require.context("./styles/global", false);
req.keys().forEach((path) => {
  import(`./styles/global/${path.replace("./", "")}`);
});
