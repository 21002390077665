<!-- 列表头部
  isCheck：Boolean，//是否存在全选，可不传，默认存在
  v-model: Boolean, //全选，若存在，必传
  sortList: Array, //排序选项，可不传，默认不显示排序
  orderBy: Number, //排序值,可不传，默认2
  isPageList：Boolean，//是否存在每页条数，可不传，默认存在
  pageList: Array, //每页条数选项，可不传，默认[10, 20, 30, 50, 100, 200]
  pageSize: Number, //每页条数，必传，默认1
  pageNum: Number, //当前页码，必传，默认1
  totalSize: Number, //总条数，可不传，默认0
  isPage：Boolean，//是否存在右侧总条数+当前页码，可不传，默认存在

  @check：全选回传，true / false
  @sort：排序回传，number数字
  @pageSize：每页条数回传，number数字
  @pageNum：当前页码回传，number数字

  使用方法：
  <g-listtop
    :isCheck="true"
    v-model="other.checkedAll"
    :sortList="other.sortList"
    :orderBy="search.orderBy"
    :isPageList="true"
    :pageList="other.pageList"
    :pageSize="search.pageSize"
    :pageNum="search.pageNum"
    :totalSize="list.totalSize"
    :isPage="true"
    @check="(e) => (other.checkedAll = e)"
    @sort="(e) => (search.orderBy = e)"
    @pageSize="(e) => (search.pageSize = e)"
    @pageNum="(e) => (search.pageNum = e)"
  />
-->
<template>
  <div class="g-listtop no-select">
    <div class="left">
      <!-- 全选 -->
      <el-checkbox
        :value="value"
        @change="$emit('check', $event)"
        v-if="isCheck"
        >全选</el-checkbox
      >
      <!-- 排序 -->
      <ul class="sort" v-if="sortList && sortList.length">
        <li
          v-for="(item, index) in orderList"
          :key="'sort_' + index"
          :class="[
            {
              on: item.value.findIndex((e) => e == orderBy) > -1, //选中样式
            },
            item.type,
          ]"
          @click="sortFn(index)"
        >
          <span>{{ item.text }}</span
          ><em class="iconfont iconsort"></em>
        </li>
      </ul>
      <!-- 每页条数 -->
      <div class="pageSize" v-if="isPageList">
        <span>每页：</span>
        <el-popover
          placement="bottom"
          trigger="click"
          popper-class="popPageSize"
          v-model="isPageSize"
        >
          <span slot="reference"
            >{{ pageSize }}条<em class="el-icon-caret-bottom"></em
          ></span>
          <ul class="scroll">
            <li
              v-for="(item, index) in pageList"
              :key="'pagesize_' + index"
              @click="
                isPageSize = false;
                $emit('pageSize', item);
              "
            >
              {{ item }}条
            </li>
          </ul>
        </el-popover>
      </div>
      <div class="slotContent">
        <slot />
      </div>
    </div>
    <!-- 右侧 -->
    <div class="right">
      <!-- 总条数+分页 -->
      <div class="page" v-if="isPage">
        <span class="totalSize">
          共<i>{{ totalSize }}</i
          >条
        </span>
        <div class="pageNum">
          <em class="l iconfont iconyoujiankuohao" @click="PageFn('prev')"></em>
          <span>
            <i class="cur">{{ pageNum }}</i>
            <em class="fg">/</em>
            <i class="all">{{ Math.ceil(totalSize / pageSize) }}</i>
          </span>
          <em class="r iconfont iconyoujiankuohao" @click="PageFn('next')"></em>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-listtop",
  props: {
    isCheck: {
      // 是否存在全选
      type: Boolean,
      default: true,
    },
    value: Boolean, //全选
    sortList: Array, //排序选项
    orderBy: {
      //排序值
      type: Number,
      default: 2,
    },
    isPageList: {
      //是否存在每页条数
      type: Boolean,
      default: true,
    },
    pageList: {
      //每页条数选项
      type: Array,
      default: () => [10, 20, 30, 50, 100, 200],
    },
    pageSize: {
      //每页条数
      type: Number,
      default: 10,
    },
    pageNum: {
      //当前页码
      type: Number,
      default: 1,
    },
    totalSize: {
      //总条数
      type: Number,
      default: 0,
    },
    isPage: {
      //是否存在每页条数
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkAll: false, //全选
      orderList: [],
      isPageSize: false, //选择每页条数弹窗开关
    };
  },
  created() {
    this.setData();
  },
  methods: {
    // 初始数据转换
    setData() {
      this.orderList = JSON.parse(JSON.stringify(this.sortList));
    },
    // 排序
    sortFn(index) {
      let orderBy = null;
      this.orderList.forEach((el, i) => {
        if (index == i) {
          //当前排序
          if (el.value.findIndex((e) => e == this.orderBy) > -1) {
            //点击前就已选中当前排序方式
            if (el.type == "asc") {
              el.type = "desc";
              orderBy = el.value[1];
            } else {
              el.type = "asc";
              orderBy = el.value[0];
            }
          } else {
            // 切换排序方式
            el.type = "desc";
            orderBy = el.value[1];
          }
        } else {
          //非当前排序
          el.type = "asc";
        }
      });
      this.$emit("sort", orderBy);
    },

    // 上一页
    PageFn(t) {
      let pageNum = this.pageNum;
      if (t == "prev") {
        if (this.pageNum > 1) {
          pageNum = this.pageNum - 1;
        }
      } else if (t == "next") {
        if (this.pageNum < Math.ceil(this.totalSize / this.pageSize)) {
          pageNum = this.pageNum + 1;
        }
      }
      this.$emit("pageNum", pageNum);
    },
  },
};
</script>

<style lang="less" scoped>
.g-listtop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #6b6b6b;
  margin: 15px 0;

  // 左侧
  .left {
    width: 50%;
    flex: 1;
    display: flex;
    align-items: center;

    // 全选
    /deep/.el-checkbox {
      margin-right: 30px;

      .el-checkbox__input {
        .el-checkbox__inner {
          background-color: transparent;
          border: 1px solid #ccc;
        }

        &.is-checked .el-checkbox__inner {
          background-color: @theme-general;
          border-color: @theme-general;
        }
      }

      .el-checkbox__label {
        font-size: 12px;
        line-height: 16px;
        padding-left: 5px;
      }
    }

    // 排序
    .sort {
      display: flex;
      align-items: center;
      margin-right: 50px;

      li {
        position: relative;
        padding-right: 13px;
        cursor: pointer;

        &:not(:first-child) {
          margin-left: 10px;
        }

        em {
          font-size: 12px;
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          color: #999;
        }

        &.on,
        &.on em {
          color: @theme-general;
        }

        &.desc {
          em {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }

    // 每页条数
    .pageSize {
      .el-popover__reference {
        .el-icon-caret-bottom {
          font-size: 14px;
          color: #bbb;
          margin-left: 3px;
        }
      }
    }

    .slotContent {
      margin-left: 50px;
      display: flex;
      align-items: center;
    }
  }

  // 右侧：总条数/页码
  .right {
    display: flex;
    align-items: center;
    // 总条数/页码
    .page {
      display: flex;
      align-items: center;
      .totalSize {
        margin-right: 15px;
        i {
          margin: 0 5px;
          color: @theme-general;
        }
      }
      .pageNum {
        position: relative;
        padding: 0 15px;
        em.iconyoujiankuohao {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px;
          font-weight: 700;
          cursor: pointer;
          color: #bbb;
          &.l {
            left: 0px;
            transform: translateY(-50%) rotate(180deg);
          }
          &.r {
            right: 0px;
          }
          &:hover {
            color: @theme-general;
          }
        }
        span {
          .cur {
            color: @theme-general;
          }
          .fg {
            margin: 0 3px;
            color: #ccc;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
// 每页条数选项弹窗
.popPageSize {
  min-width: 90px;
  border-radius: 2px;
  padding: 5px 0px;
  ul {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    li {
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      cursor: pointer;
      &:hover {
        background-color: #f5f7fa;
      }
    }
  }
}
</style>
