//全局公共方法，调用方式为this.$commons+方法名，如this.$commons.stopScorll()
import QRCode from "qrcodejs2";
import { Message } from "element-ui";

let commons = {};

// 数据万象网址：https://cloud.tencent.com/document/product/460/52518
// const fileToPng = "?ci-process=doc-preview&dstType=png"; // 腾讯云缩略图后缀参数
// const fileToHtml = "?ci-process=doc-preview&dstType=html&htmltitle=";
// 附件迁移服务器后删除
commons.getFileViewUrl = function (url, title) {
  let t = new Date().getTime(); //t传参是
  let fileToHtmlRdm =
    "?ci-process=doc-preview&dstType=html&t=" + t + "&htmltitle=";
  let str = this.getFormat(url, 1);
  title = title ? this.safeTextToBase64(title) : this.safeTextToBase64("预览");
  let fileViewUrl = url + (str == "img" ? "" : fileToHtmlRdm + title);
  return fileViewUrl;
};
// URL 安全的 BASE64 编码,目前该方法只应用于文件预览是，传值给腾讯云文件url
commons.safeTextToBase64 = function (str) {
  let Base64 = require("js-base64").Base64;
  str = Base64.encode(str); //Base64.encode(item.attachName); //代码文本， 需Base64处理
  str = str.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "");
  return str;
};
commons.getFormat = function (str, backType) {
  let format = "";
  if (str) {
    format = str.substring(str.lastIndexOf(".") + 1);
  }
  if (backType) {
    let data = [
      { format: "excel", type: ["xls", "xlsx"] },
      { format: "word", type: ["doc", "docx"] },
      { format: "img", type: ["jpg", "jpeg", "png", "gif"] },
      { format: "music", type: ["mp3", "mp4"] },
      { format: "pdf", type: ["pdf"] },
      { format: "txt", type: ["txt"] },
      { format: "ppt", type: ["ppt"] },
    ];
    let img = "no";
    if (format) {
      data.forEach((el) => {
        if (el.type.findIndex((e) => e == format) > -1) {
          img = el.format;
        }
      });
    }
    if (backType == 1) {
      return img; //返回格式名称
    } else if (backType == 2) {
      return require("@/assets/public/office/ico_" + img + ".png"); //返回格式小图标
    } else if (backType == 3) {
      return require("@/assets/public/office/" + img + ".png"); //返回格式大图片
    }
  } else {
    return format; //返回格式名如docx、xml、png等，不带.
  }
};

//生成二维码
commons.creatQrFn = function (url, id, size) {
  new QRCode(id, {
    width: size, // 设置宽度，单位像素
    height: size, // 设置高度，单位像素
    text: "" + url, // 设置二维码内容或跳转地址
  });
};

//下载生成的二维码
commons.downLoadQrFn = function (id) {
  let myCanvas = document.getElementById(id).getElementsByTagName("canvas");
  let a = document.createElement("a");
  a.href = myCanvas[0].toDataURL("image/png");
  a.download = "二维码";
  a.click();
};
//弹窗提示
commons.voit = function (val, type, duration) {
  Message({
    message: val || "功能开发中，敬请期待",
    type: type ? type : "warning",
    duration: duration || 1500,
  });
};

//宽度自适应,value为选中文字
commons.autoWidth = function (value, level) {
  if (value == "" || value == 0) {
    return "85px";
  } else {
    return String(value).length * (level ? level : 12) + 15 + "px";
  }
};

//级联宽度自适应,num为选中个数
commons.autoCasWidth = function (num) {
  if (num == 0) {
    return "85px";
  } else {
    return num * 75 + 15 + "px";
  }
};
commons.getWidth = function (text) {
  if (text instanceof Array) {
    text = text.join("");
  }
  text = text + "";
  let arr = text.split("");
  let en = 0;
  let ch = 0;
  arr.forEach((ele) => {
    if (ele.charCodeAt(0) > 127 || ele.charCodeAt(0) == 94) {
      ch++;
    } else {
      en++;
    }
  });
  return (text.length !== 0 ? en * 8.5 + ch * 15 + 10 : 40) + "px";
};
//文本框插入内容函数封装，分为获取光标位置与设置光标位置

// 获取光标位置
commons.getCursorPosFn = function (el, type) {
  //type为1获取光标开始位置，type为2获取结束位置
  let pos = 0;
  if (el.selectionStart || el.selectionStart === 0) {
    if (type == 1) {
      pos = el.selectionStart; // 获取选区开始位置
    } else {
      pos = el.selectionEnd; // 获取选区结束位置
    }
  }
  return pos;
};
// 设置光标位置
commons.setCaretPosFn = function (el, pos) {
  if (el.setSelectionRange) {
    el.focus();
    el.setSelectionRange(pos, pos);
  } else if (el.createTextRange) {
    const range = el.createTextRange();
    range.collapse(true);
    range.moveEnd("character", pos);
    range.moveStart("character", pos);
    range.select();
  }
};

// 时间倒计时，msec为总秒数
commons.countdownFn = function (msec) {
  let ssss = msec % (60 * 60 * 24); //取模（余数
  let day = (msec - ssss) / (60 * 60 * 24);
  if (ssss >= 0) {
    let hh = Math.floor(ssss / (60 * 60)); //小时数
    ssss = ssss % (60 * 60);
    let mm = Math.floor(ssss / 60); //分钟
    let ss = ssss % 60; //秒
    hh = hh < 10 ? "0" + hh : hh;
    mm = mm < 10 ? "0" + mm : mm;
    ss = ss < 10 ? "0" + ss : ss;

    //延迟一秒执行自己
    // timer=setTimeout(function () {
    //   commons.countdownFn(msec-1);
    // }, 1000)
    if (day) hh = day + "日" + hh;

    return hh + ":" + mm + ":" + ss;
  }
};

//生成随机数,len为长度
commons.createCode = function (len) {
  let code = "";
  //设置随机字符
  let random = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  //循环codeLength
  for (let i = 0; i < len; i++) {
    let id = Math.ceil(Math.random() * 61);
    code += random[id];
  }
  return code;
};

//生成不重复n位数字随机数,len为长度,count表示需要生成的随机数的个数
commons.createNumCode = function (len, count) {
  let number = 1;
  for (let i = 0; i < len; i++) {
    number = number * 10;
  }
  let res = new Array();
  for (let i = 0; i < count; i++) {
    let num = Math.round(Math.random() * number);
    if (!commons.exist(res, num)) {
      res.push(
        (num + "").length >= len
          ? num
          : num + commons.getZeroStr(len - (num + "").length)
      );
    } else {
      i--;
    }
  }
  return res;
};
/**
 * 位数不够，后面补0
 */
commons.getZeroStr = function (len) {
  let str = "";
  for (let i = 0; i < len; i++) {
    str += "0";
  }
  return str;
};
/**
 * 数组arr中是否存在obj元素
 */
commons.exist = function (arr, obj) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] == obj) {
      return true;
    }
  }
  return false;
};

commons.getTime = function (time, len) {
  return String(time || "--").substring(0, len);
};
//时间戳转换
commons.MillisecondToDate = function (value) {
  if (!value) {
    return false;
  }
  let date;
  if (typeof value == "string" && value.constructor == String) {
    date = new Date(value.replace(/-/g, "/"));
  } else {
    date = new Date(value);
  }
  let y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    h = date.getHours(),
    i = date.getMinutes(),
    s = date.getSeconds();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  if (h < 10) {
    h = "0" + h;
  }
  if (i < 10) {
    i = "0" + i;
  }
  if (s < 10) {
    s = "0" + s;
  }
  let t = value ? y + "-" + m + "-" + d + " " + h + ":" + i : "--";
  return t;
};

//将base64转换为file类型
commons.dataURLtoFile = function (dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

// 密码安全级别检测
commons.analyzePasswordSecurityLevel = function (password) {
  let securityLevelFlag = 0;
  if (password.length < 6) {
    return 0;
  } else {
    var securityLevelFlagArray = new Array(0, 0, 0, 0);
    for (var i = 0; i < password.length; i++) {
      var asciiNumber = password.substr(i, 1).charCodeAt();
      if (asciiNumber >= 48 && asciiNumber <= 57) {
        securityLevelFlagArray[0] = 1; //digital
      } else if (asciiNumber >= 97 && asciiNumber <= 122) {
        securityLevelFlagArray[1] = 1; //lowercase
      } else if (asciiNumber >= 65 && asciiNumber <= 90) {
        securityLevelFlagArray[2] = 1; //uppercase
      } else {
        securityLevelFlagArray[3] = 1; //specialcase
      }
    }

    for (let i = 0; i < securityLevelFlagArray.length; i++) {
      if (securityLevelFlagArray[i] == 1) {
        securityLevelFlag++;
      }
    }
    return securityLevelFlag - 1;
  }
};

//检测是否支持全屏
commons.isSupportFullScreenFn = function () {
  let isSupportFullScreen = false;
  let docElm = document.documentElement;
  if (docElm.requestFullscreen) {
    isSupportFullScreen = true;
  } else if (docElm.msRequestFullscreen) {
    isSupportFullScreen = true;
  } else if (docElm.mozRequestFullScreen) {
    isSupportFullScreen = true;
  } else if (docElm.webkitRequestFullScreen) {
    isSupportFullScreen = true;
  } else {
    isSupportFullScreen = false;
  }
  return isSupportFullScreen;
};

//开启/关闭全屏
commons.startFullScreenFn = function (isFullscreen) {
  let element = document.documentElement;
  if (isFullscreen) {
    //有则退出
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    //开启
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      // IE11
      element.msRequestFullscreen();
    }
  }
  isFullscreen = !isFullscreen;
};

//post导出文件,res:返回的二进制流，name:文件名
commons.submitDownload = function (res, name, url) {
  const link = document.createElement("a"); // 创建元素
  let blob = new Blob([res], { type: "application/vnd.ms-excel" });
  link.style.display = "none";
  link.href = url ? url : URL.createObjectURL(blob); // 创建下载的链接
  //num++
  link.setAttribute("download", name); // 给下载后的文件命名
  document.body.appendChild(link);
  link.click(); // 点击下载
  document.body.removeChild(link); //  下载完成移除元素
  window.URL.revokeObjectURL(link.href); // 释放掉blob对象
};

//判断一串数字是否是连续的
commons.isContinuityNum = function (num) {
  let array = [];
  if (num instanceof Array) {
    array = [...num];
  } else {
    array = Array.from(num.toString()); //转换为数组
  }

  var i = array[0];
  var isContinuation = true;
  for (var e in array) {
    if (array[e] != i) {
      isContinuation = false;
      break;
    }
    i++;
  }
  return isContinuation;
};

commons.downFn = function (url, name) {
  let uu = url.replace(/\+/g, "%2B"); //encodeURI(url)
  console.log(uu, "uu=========");
  fetch(uu).then((res) => {
    res.blob().then((myBlob) => {
      const href = URL.createObjectURL(myBlob);
      const a = document.createElement("a");
      a.href = href;
      a.download = name; // 下载文件重命名
      a.click();
      a.remove();
    });
  });
};
commons.download = function (url, name, type, method) {
  url = url.replace(/\+/g, "%2B"); //encodeURI(url)
  console.log(url, name, type, "params=====");
  var xhr = new XMLHttpRequest();
  xhr.open(method ? method : "GET", url, true);
  //xhr.setRequestHeader("Content-Type","application/json");
  xhr.responseType = "blob"; // 返回类型blob
  // 定义请求完成的处理函数，请求前也可以增加加载框/禁用下载按钮逻辑
  xhr.onload = function (res) {
    // 请求完成
    console.log(this.status, "this.status");
    if (this.status === 200) {
      // 返回200
      var blob = this.response;
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      console.log(res, blob, href, "导出");
      if (blob.type == "application/json" || blob.size == 0) {
        Message({
          message: "导出失败",
          type: "error",
        });
        return;
      }
      console.log(type, "type=");
      var date = new Date(); //实例一个时间对象；
      var y = date.getFullYear(); //获取系统的年；
      var M =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1; //获取系统月份，由于月份是从0开始计算，所以要加1
      var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //获取系统日
      var h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //获取系统时间
      var mm =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //分
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
      name = name + "_" + y + M + d + h + mm + ss;

      //判断是否是IE浏览器，是的话返回true
      if (window.navigator.msSaveBlob) {
        try {
          window.navigator.msSaveBlob(blob, name + type);
        } catch (e) {
          console.log(e, "eee");
        }
      } else {
        // 谷歌浏览器 创建a标签 添加download属性下载
        var downloadElement = document.createElement("a");
        downloadElement.href = href;
        downloadElement.target = "_blank";
        downloadElement.download = name + type; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    } else {
      if (this.status == 404) {
        Message({ message: "导出失败,文件未找到", type: "error" });
      } else {
        Message({ message: "导出失败", type: "error" });
      }
      return;
    }
  };
  // 发送ajax请求
  xhr.send();
};

//通知：附件字符串处理，返回对应数据
commons.temInfo = function (item, type) {
  let info = "";
  let arr = [];
  let l = 0;
  if (type == "name") {
    //返回附件名称
    arr = item.split("/");
    l = arr.length;
    info = arr[l - 1];
  } else if (type == "format") {
    //返回格式
    arr = item.split(".");
    l = arr.length;
    info = arr[l - 1];
  } else if (type == "err") {
    //返回格式对应的error图片
    arr = item.split(".");
    l = arr.length;
    switch (arr[l - 1].toLowerCase()) {
      case "docx":
      case "doc":
        info = require("@/assets/public/office/word.png");
        break;
      case "xlsx":
      case "xls":
        info = require("@/assets/public/office/excel.png");
        break;
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
        info = require("@/assets/public/office/jpg.png");
        break;
      case "pdf":
        info = require("@/assets/public/office/pdf.png");
        break;
      case "pptx":
      case "ppt":
        info = require("@/assets/public/office/ppt.png");
        break;
      case "txt":
        info = require("@/assets/public/office/txt.png");
        break;
      case "rar":
      case "zip":
      case "7z":
      case "arj":
        info = require("@/assets/public/office/rar.png");
        break;
      case "avi":
      case "mov":
      case "qt":
      case "asf":
      case "rm":
      case "navi":
      case "mp4":
        info = require("@/assets/public/office/video.png");
        break;
      case "mp3":
      case "mpeg":
      case "wma":
      case "ra":
      case "rmx":
        info = require("@/assets/public/office/music.png");
        break;
      case "html":
      case "htm":
      case "java":
      case "jsp":
      case "php":
      case "css":
      case "js":
      case "asp":
      case "vue":
      case "xml":
        info = require("@/assets/public/office/code.png");
        break;
      default:
        info = require("@/assets/public/office/no.png");
        break;
    }
  } else if (type == "img") {
    //返回正常图片
    arr = item.split(".");
    l = arr.length;
    if (
      arr[l - 1].toLowerCase() == "jpg" ||
      arr[l - 1].toLowerCase() == "jpeg" ||
      arr[l - 1].toLowerCase() == "gif" ||
      arr[l - 1].toLowerCase() == "png"
    ) {
      info = item;
    } else {
      info = ""; //后续开发文档转图片
    }
  }
  return info;
};
//设置附件选择格式范围
commons.fileType = function (type) {
  if (type == 1) {
    //图片
    //return 'image/jpeg,image/png,image/gif';
    return ".jpg,.png,.gif";
  } else if (type == 2) {
    //文档
    //return 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/ppt, application/pdf';
    return ".doc,docx,.xls,.xlsx,.pdf,.ppt,.pptx";
  } else if (type == 3) {
    //压缩包
    return ".zip,.rar,.7z";
  } else if (type == 4) {
    //视频
    //return 'video/*';
    return ".ogm,.wmv,.avi,.m4v,.mpg,.mpeg,.mp4";
  } else if (type == 5) {
    //音频
    //return 'audio/*';
    return ".mp3,.wma,.m4a,.ogg,.wav";
  } else {
    return "*";
  }
};

//文本域内容在div中显示时处理换行
commons.textToDiv = function (content) {
  return content.replace(/\n/g, "<br>");
};

//三方登录,type登录类型，platformInfo平台信息，page回调页面
commons.otherLogin = function (type, platformInfo, page) {
  let url = "";
  let appId;
  let hostname = "asktrue.cn"; //location.hostname
  if (type == 1) {
    appId = platformInfo.wxAppidKf || "wx7a8d886f94e63942";
    url =
      "https://open.weixin.qq.com/connect/qrconnect?appid=" +
      appId +
      "&redirect_uri=" +
      encodeURIComponent("http://" + hostname + "/" + page + "loginType=wx") +
      "&response_type=code&scope=snsapi_login";
  } else if (type == 2) {
    appId = platformInfo.qqAppid || "101892283";
    url =
      "https://graph.qq.com/oauth2.0/authorize?client_id=" +
      appId +
      "&redirect_uri=" +
      encodeURIComponent("http://" + hostname + "/" + page + "loginType=qq") +
      "&response_type=code";
  } else if (type == 3) {
    appId = platformInfo.sinaAppid || "3752660226";
    // https://api.weibo.com/oauth2/authorize?client_id=YOUR_CLIENT_ID&response_type=code&redirect_uri=YOUR_REGISTERED_REDIRECT_URI
    url =
      "https://api.weibo.com/oauth2/authorize?client_id=" +
      appId +
      "&response_type=code" +
      "&redirect_uri=" +
      encodeURIComponent("http://" + hostname + "/#/" + page);
  } else {
    appId = platformInfo.alipayAppid || "2021001189622095";
    url =
      "https://auth.alipay.com/login/index.htm?goto=https%3A%2F%2Fopenauth.alipay.com%3A443%2Foauth2%2FpublicAppAuthorize.htm%3Fapp_id%3D" +
      appId +
      "%26scope%3Dauth_user%26redirect_uri%3D" +
      encodeURIComponent(
        "http://" + hostname + "/" + page + "loginType=alipay"
      ) +
      "%26response_type%3Dcode%26scope%3Dsnsapi_login";
  }
  return url;
};

commons.UrlSearch = function (strV) {
  var obj = {};
  var name, value;
  var str = strV ? strV : location.href; //取得整个地址栏
  var num = str.indexOf("?");
  str = str.substr(num + 1); //取得所有参数 stringvar.substr(start [, length ]

  var arr = str.split("&"); //各个参数放到数组里
  for (var i = 0; i < arr.length; i++) {
    num = arr[i].indexOf("=");
    if (num > 0) {
      name = arr[i].substr(0, num);
      value = arr[i].substr(num + 1);
      obj[name] = decodeURIComponent(value);
    }
  }
  return obj;
};

commons.decode64 = function (text) {
  return new TextDecoder().decode(
    Uint8Array.from(atob(text), (c) => c.charCodeAt(0))
  );
};

commons.flattenTree = function (treeData, children) {
  console.log(treeData, "treeData");
  const result = [];
  function traverse(node) {
    console.log(node, "node");
    if (node[children] && node[children].length > 0) {
      for (const child of node[children]) {
        traverse(child);
      }
    }
    delete node[children];
    console.log(node, "push node");
    result.push(node);
  }

  treeData.forEach((data) => {
    traverse(data);
  });
  return result;
};

export default commons;
