// ecommencre接口

import qs from "qs"; // 序列化

export function formData(url, data) {
  console.log('aaaaaaaaaaaaaaaaaaaaaaaa')
  return {
    url: url,
    method: "post",
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data);// 将请求数据转换成功 formdata 接收格式
      },
    ],
  };
};

export default function (axios) {
  return {
    // 提交订单的时候 首先往订单表里面插入一条订单记录
    insertGoodsOrderApi(params) {
      return axios.post("/buyGoodsCtl/insertGoodsOrder", params)
    },
    // 查询订单详情
    selectOrderInfoApi(params) {
      return axios.get("/goodsOrder/anon/selectOrderInfo", { params })
    },


    // 第三方支付插入流水表
    thirdPartyPayBeforeApi(params) {
      return axios(formData("/buyGoodsCtl/thirdPartyPayBefore", params))
    },
    // 支付宝支付
    aliPay(params) {
      return axios(formData("/anon/aliPayCtl/qrcode", params))
    },

    // 微信支付
    wxPay(params) {
      return axios(formData("/anon/weChatPayCtl/qrcode", params))
    },

    // 获取支付宝和微信支付状态
    getAliAndWxStatus(params) {
      return axios.get("/buyGoodsCtl/selectTransactionFlowingStatus", { params })
    },
    // 三方支付支付成功之后 修改订单状态 以及增加充值记录 以及对应的商品余额
    addGoodsRecords(params) {
      return axios.post("/buyGoodsCtl/addGoodsRecords", {}, { params })
    },


    getAllGoodsType(data) {
      return axios.post("/goodsType/allGoodsType");
    },

  };
}
