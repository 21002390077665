<template>
  <el-dialog
    class="dialog-scope-setting"
    title="分享课程"
    :visible="visible"
    width="468px"
    top="30vh"
    @close="$emit('update:visible', false)"
    @closed="handleClosed"
    @open="handleOpen"
    append-to-body
  >
    <div class="share-wrapper">
      <div>
        <!-- 分享渠道 -->
        <div class="share-third">
          <div class="weixin" style="display: inline">
            <em class="iconfont iconweixin"></em>
            <div class="wcode">
              <div class="img-outer" ref="wCQrCode" id="wCQrCode"></div>
              <p class="f14 mt5">微信扫码分享</p>
            </div>
          </div>
          <em class="iconfont iconxinlang" @click="postShareFn('wb')"></em>
          <!-- <em class="iconfont iconqqzone" @click="postShareFn('qzone')"></em> -->
          <em
            class="iconfont iconqq"
            title="分享到QQ好友"
            @click="postShareFn('qq')"
          ></em>
        </div>
        <div class="copy-wrapper">
          <span>课程地址：</span>
          <input type="text" readonly v-model="shareUrl" class="text" />
          <button
            class="copy-btn"
            v-clipboard:copy="shareUrl"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            复制
          </button>
        </div>
      </div>
      <div class="qrcode-wrapper">
        <p>扫码分享</p>
        <div
          ref="qrCode"
          id="shareQrCode"
          class="qr-code"
          title="扫码更健康"
        ></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "dialog-share",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    courseId: {
      type: [Number, String],
    },
    detail: {
      type: Object,
    },
  },
  data() {
    return {
      shareUrl: "",
    };
  },
  methods: {
    handleClosed() {
      console.log("closed");
    },
    handleClose() {
      this.$emit("update:visible", false);
    },
    handleOpen() {
      console.log("open");
      let url = window.location.origin + `/#/play?id=${this.courseId}`;
      // TODO 转为短链接
      this.shareUrl = url;
      this.$nextTick(() => {
        this.$refs.qrCode.innerHTML = "";
        this.$commons.creatQrFn(this.shareUrl, "shareQrCode", 70);

        this.$refs.wCQrCode.innerHTML = "";
        this.$commons.creatQrFn(this.url, "wCQrCode", 120);
      });
    },

    //打开窗口
    postShareFn(type) {
      let url = this.getHref(type);
      window.open(url);
    },

    //复制链接
    onCopy() {
      this.$message.success("内容已复制到剪切板！");
    },
    onError() {
      this.$message.error("抱歉，复制失败！");
    },

    //获取分享内容
    getHref(type) {
      let share = {
        title: this.detail.courseName,
        desc: this.detail.courseDescription,
        share_url: this.shareUrl,
        image_url: this.detail.coursePic,
      };
      if (type == "wb") {
        return (
          "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&pic=" +
          share.image_url +
          "&searchPic=true"
        );
      } else if (type == "qq") {
        return (
          "https://connect.qq.com/widget/shareqq/index.html?url=" +
          encodeURIComponent(share.share_url) +
          "&title=" +
          share.title +
          "&desc=" +
          share.desc
        );
      } else if (type == "qzone") {
        return (
          "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
          encodeURIComponent(share.share_url) +
          "&sharesource=qzone&title=" +
          share.title +
          "&pics=" +
          share.image_url +
          "&desc=" +
          share.desc
        );
      } else if (type == "wx") {
        console.log(share.share_url, "==share.share_url");
        return (
          "https://qr.liantu.com/api.php?text=" +
          encodeURIComponent(share.share_url)
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.share-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.share-third {
  text-align: left;
  // padding-left: 10px;
  em {
    display: inline-block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    margin-right: 12px;
    cursor: pointer;
  }
  em.iconweixin {
    background-color: #00d700;
  }
  em.iconxinlang {
    background-color: #e6162d;
    font-size: 18px;
  }
  em.iconqq {
    background-color: #0075c2;
    font-size: 18px;
  }
  em.iconqqzone {
    background-color: #fabc13;
    font-size: 22px;
  }
}

.copy-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  input {
    border: 1px solid #e4e4e4;
    line-height: 32px;
  }
  .copy-btn {
    margin-left: 10px;
    height: 32px;
    width: 64px;
    border: 1px solid #3399cc;
    color: #39c;
    background-color: white;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background-color: #39c;
      color: white;
    }
  }
}

.qrcode-wrapper {
  text-align: center;
  p {
    color: #666;
  }
  .qr-code {
    padding: 5px;
  }
}

.weixin {
  position: relative;
  .wcode {
    display: none;
    position: absolute;
    border-radius: 3px;
    left: 35px;
    top: -70px;
    width: 160px;
    height: 170px;
    padding-top: 20px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    z-index: 9;
    .img-outer {
      width: 120px;
      margin-left: 20px;
      padding: 10px;
      background: #fff;
      :deep(img) {
        width: 100px;
        height: 100px;
      }
    }
  }
  &:hover .wcode {
    display: block;
  }
}
</style>
