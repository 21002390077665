<!--页签-->
<template>
  <div class="g-tab">
    <span
      class="tab"
      v-for="e in tabs"
      :key="e.value"
      :class="{ on: value == e.value }"
      @click="tabFn(e.value)"
      >{{ e.label }}</span
    >
  </div>
</template>

<script>
export default {
  name: "g-tab",
  props: {
    value: [Number, String],
    tabs: Array,
  },
  data() {
    return {};
  },
  methods: {
    tabFn(i) {
      this.$emit("input", i);
    },
  },
};
</script>

<style lang="less" scoped>
.g-tab {
  display: flex;
  align-items: center;
  gap: 40px;
  span {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    &.on {
      color: #39c;
      // &::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   width: 50%;
      //   height: 2px;
      //   border-radius: 2px;
      //   background-color: #409eff;
      // }
    }
    &::after {
      content: "";
      position: absolute;
      right: -20px;
      top: 50%;
      transform: translateY(-50%);
      height: 12px;
      width: 1px;
      background-color: #e4e4e4;
    }
    &:last-child::after {
      display: none;
    }
  }
}
</style>
