import commons from "@/js/commom.js";
export default {
  namespaced: true,
  local: true,
  state: {
    goodsType: [], //商品类目
    goodsTypeMap: {},
  },
  mutations: {
    params(state, info) {
      if (info.goodsType) {
        state.goodsType = info.goodsType;
        let goodsTypeList = commons.flattenTree(
          JSON.parse(JSON.stringify(info.goodsType)),
          "child"
        );
        let goodsTypeMap = {};
        goodsTypeList.forEach((goodsType) => {
          goodsTypeMap[goodsType.value] = goodsType;
        });
        state.goodsTypeMap = goodsTypeMap;
      }
    },
  },
};
