// import md5 from "@/assets/utils/md5";
// import common from "@/assets/utils/commom";
export default function (axios) {
  return {
    // 修改企业会员信息
    changeCompanyInfo(info) {
      return axios.post("/compMemberCtl/updateMemberComp", info);
    },
    // 安全页面修改企业密码
    changePsdComReq(info) {
      // info.newPassWord = md5.md5(info.newPassWord);
      // info.newPassWordRep = md5.md5(info.newPassWordRep);
      // info.passWord = md5.md5(info.passWord);
      return axios.post("/compMemberCtl/updatePassWord", {}, { params: info });
    },
    // 校验邮箱验证码
    checkVerifyEmail(info) {
      return axios.get("/compMemberCtl/checkVerifyEmail", { params: info });
    },
    // 校验手机号验证码
    checkVerifyPhone(info) {
      return axios.get("/compMemberCtl/checkVerifyPhone", { params: info });
    },
    // 绑定企业账号邮箱
    bindEmail(info) {
      return axios.post("/compMemberCtl/bindEmail", {}, { params: info });
    },
    // 绑定企业账号手机号
    bindMobile(info) {
      return axios.post(
        "/compMemberCtl/confirmMobileCode",
        {},
        { params: info }
      );
    },
  };
}
