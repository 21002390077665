// import md5 from "@/assets/utils/md5";
// import common from "@/assets/utils/commom";
export default function (axios) {
  return {
    // 获取员工列表
    getStaff(par, controller) {
      par.systemId = 8;
      let signal =
        controller && controller.signal ? { signal: controller.signal } : {};
      let params = { ...par, ...signal };
      return axios.get("/companyStaffMemberCtl/selectCompStaffMemberPage", {
        params,
      });
    },
    // 修改企业会员信息
    // changeCompanyInfo(info) {
    //   return axios.post("/compMemberCtl/updateMemberComp", info);
    // },
    // 获取会员信息
    getMemberInfo(params) {
      return axios.get("/companyStaffMemberCtl/selectCompStffMember", {
        params,
      });
    },
    // 修改会员信息
    changeMemberInfo(info) {
      return axios.post("/companyStaffMemberCtl/updateCompStaff", info);
    },
    // 安全页面修改企业密码
    // changePsdComReq(info) {
    //   // info.newPassWord = md5.md5(info.newPassWord);
    //   // info.newPassWordRep = md5.md5(info.newPassWordRep);
    //   // info.passWord = md5.md5(info.passWord);
    //   return axios.post("/compMemberCtl/updatePassWord", {}, { params: info });
    // },
    // 安全页面修改员工密码
    changePsdStaffReq(info) {
      return axios.post(
        "/companyStaffMemberCtl/updatePassWord",
        {},
        { params: info }
      );
    },
    // 获取验证码
    getSafeyCode(params) {
      return axios.get("/anon/verifyCode", { params });
    },
    // 校验邮箱验证码
    // checkVerifyEmail(info) {
    //   return axios.get("/compMemberCtl/checkVerifyEmail", { params: info });
    // },
    // 校验手机号验证码
    // checkVerifyPhone(info) {
    //   return axios.get("/compMemberCtl/checkVerifyPhone", { params: info });
    // },
    // 绑定企业账号邮箱
    // bindEmail(info) {
    //   return axios.post("/compMemberCtl/bindEmail", {}, { params: info });
    // },
    // 绑定企业账号手机号
    // bindMobile(info) {
    //   return axios.post(
    //     "/compMemberCtl/confirmMobileCode",
    //     {},
    //     { params: info }
    //   );
    // },
    // 绑定企业子账号邮箱
    bindChildEmail(info) {
      return axios.post(
        "/companyStaffMemberCtl/bindEmail",
        {},
        { params: info }
      );
    },
    // 绑定企业子账号手机号
    bindChildMobile(info) {
      return axios.post(
        "/companyStaffMemberCtl/confirmMobileCode",
        {},
        { params: info }
      );
    },

    // 设为管理员
    setAdmin(params) {
      params.systemId = 8;
      let url = "/companyStaffMemberCtl/setStaffAdmin"; //新增
      return axios.get(url, { params });
    },


  };
}
