<!-- 列表简单分页：总 5 页 < 1/5 > -->
<template>
  <div class="page">
    <span class="totalSize">
      共<i>{{ totalSize }}</i
      >条
    </span>
    <div class="pageNum">
      <em class="l iconfont iconyoujiankuohao" @click="PageFn('prev')"></em>
      <span>
        <i class="cur">{{ currentPage }}</i>
        <em class="fg">/</em>
        <i class="all">{{ Math.ceil(totalSize / pageSize) }}</i>
      </span>
      <em class="r iconfont iconyoujiankuohao" @click="PageFn('next')"></em>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-listpage",
  props: {
    totalSize: Number,
    currentPage: Number,
    pageSize: Number,
  },
  methods: {
    // 上一页
    PageFn(t) {
      let pageNum = this.currentPage;
      if (t == "prev") {
        if (pageNum > 1) {
          pageNum = pageNum - 1;
        }
      } else if (t == "next") {
        if (pageNum < Math.ceil(this.totalSize / this.pageSize)) {
          pageNum = pageNum + 1;
        }
      }
      this.$emit("pageNum", pageNum);
    },
  },
};
</script>

<style lang="less" scoped>
em,
i {
  font-style: normal;
  text-decoration: none;
}
.page {
  display: flex;
  align-items: center;
  .totalSize {
    margin-right: 5px;
    i {
      margin: 0 5px;
      color: @theme-general;
    }
  }
  .pageNum {
    position: relative;
    padding: 0 15px;
    em.iconyoujiankuohao {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px;
      font-weight: 700;
      cursor: pointer;
      color: #bbb;
      &.l {
        left: 0px;
        transform: translateY(-50%) rotate(180deg);
      }
      &.r {
        right: 0px;
      }
      &:hover {
        color: @theme-general;
      }
    }
    span {
      .cur {
        color: @theme-general;
      }
      .fg {
        margin: 0 3px;
        color: #ccc;
      }
    }
  }
}
</style>
