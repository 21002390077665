export default {
  parent: "mall",
  routers: [
    // 商城-课程
    {
      path: "course",
      name: "sc-course",
      component: () => import("@/views/train/mall/courseView.vue"),
      meta: { title: "商城" },
    },
    // 商城-直播
    {
      path: "live",
      name: "sc-live",
      component: () => import("@/views/train/mall/liveView.vue"),
      meta: { title: "商城" },
    },
    // 商城-培训班
    {
      path: "class",
      name: "sc-class",
      component: () => import("@/views/train/mall/classView.vue"),
      meta: { title: "商城" },
    },
  ],
};
