// 学员接口
export default function (axios) {
  return {
    // 学员-新增
    xy_addApi(params) {
      return axios.post("/cultivate/trainee/addTrainee", params);
    },
    // 学员-删除
    xy_delApi(params) {
      return axios.get("/cultivate/trainee/deleteTrainee", { params });
    },
    // 学员-列表
    xy_listApi(params) {
      return axios.post("/cultivate/trainee/selectInPage", params);
    },
    // 学员-列表 外部
    xy_listOutApi(params) {
      return axios.post("/cultivate/trainee/selectOutPage", params);
    },
    // 给学员添加必修课
    xy_addClass(params) {
      return axios.post(`/cultivate/class/addClass`, params);
    },
    // 学员-课程
    xyDeatil_courselistApi(params) {
      return axios.post("/cultivate/class/selectClassByTrainee", params);
    },
    // 学员-课程-设置必修/选修/删除课程
    xyDeatil_updateTraineeCourseApi(params) {
      return axios.post("/cultivate/trainee/updateTraineeCourse", params);
    },
    // 学员-移除课程
    xyDeatil_coursedeleteApi(params) {
      return axios.get("/cultivate/class/deleteClass", { params });
    },
    // 学员-班级
    xyDeatil_traineelistApi(params) {
      return axios.post("/cultivate/class/selectClassByTraineeId", params);
    },
    // 学员-考评
    xyDeatil_examlistApi(params) {
      return axios.post("/cultivate/class/selectExamByTrainee", params);
    },
    // 学员-直播
    xyDeatil_liveListApi(params) {
      return axios.post("/cultivate/class/selectLiveByTrainee", params);
    },
    // 学员-详情
    xy_detailApi(params) {
      return axios.post(`/cultivate/trainee/getTraineeInfo`, params);
    },
    // 学员-详情统计
    xy_selectCountApi(params) {
      return axios.post(`/cultivate/class/selectCountTrainee`, params);
    },
  };
}
