export default function (axios) {
  return {
    //平台
    savePlatReq(data) { return axios.post("/admin/plat/save", data, { conType: 'application/json;charset=UTF-8' }) },//新增编辑
    resetPassPlatReq(data) { return axios.post("/admin/plat/resetting", data) },//重置密码
    deletePlatReq(data) { return axios.delete("/admin/plat/delete", data) },//重置密码
    seletPlatDetail(data) { return axios.get("/admin/plat/select/" + data) },//获取某平台详情
    selectPlatList(data) { return axios.post("/admin/plat/selectList", data) },//获取平台列表


    //设置-查询
    selectAdminReq(data) { return axios.get("/admin/plat/selectAdmin", data) },
    //设置-保存
    updateAdminMngStyleReq(data) { return axios.post("/admin/plat/updateAdminMngStyle", data, { conType: 'application/json' }) },

  }
}