// const formData = {
//   headers: {
//     "Content-Type": "application/x-www-form-urlencoded",
//     Accept: "application/json, text/plain",
//   },
// };
export default function (axios) {
  return {
    // 获取平台信息
    getPlatInfo(params) {
      return axios.get("/admin/plat/selectNose", { params });
    },
    // 登录接口
    login(params) {
      let pat = {
        ...params,
        systemId: 8,
      };
      return axios.post("/anon/generateToken", pat, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    // 退出登录接口
    logout() {
      return axios.get("/invalidToken");
    },
    // 获取企业会员信息
    getCompanyInfo(params) {
      return axios.get("/companyMemberCtl/selectCompMemberById", { params });
    },

    // 获取可切换的身份列表
    getUserList() {
      return axios.get("/changeUserList?systemId=8");
    },
    // 切换用户身份
    changeUser(params) {
      return axios.get("changeUser", { params });
    },

    //用户注册
    postRegReq(params) {
      return axios.post("/anon/register", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    // 按部门选员工
    selectorReq(params, tab) {
      let url = "";
      if (tab == "a" || tab == "b") {
        url = "/cCompDepartment/selectCompanyForStudy";
      } else if (tab == "c") {
        url = "/cCompDepartment/selectDeptByCompId";
      } else if (tab == "d") {
        url = "/compStaffMember/selectPostByCompId";
      } else if (tab == "e") {
        url = "/internal/staffArchiveCtl/findStaffPositionNose";
      }
      return axios.get(url, { params });
    },
  };
}
